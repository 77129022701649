import React from 'react';

const LiveIcon = (props) => {
    const {style} = props
    return (
        <div className="bg-danger d-flex align-items-center justify-content-center live-auction-button" style={{...style, borderRadius: '2px', padding: '0 4px' }}>
            <div className="text-white text-uppercase font-weight-bold" style={{ fontSize: '.8rem' }}>Live</div>
        </div>
    )
}

export default LiveIcon;