import React, { useState, useEffect } from "react";
import ConfirmationModal from "../../../common/modal/confirmation-modal.jsx";
import RegisterForm from "../../../account/login/index.js";
import RegisterCompleteModal from "../../../common/modal/index.js";
import RegistrationButton from "./registrationButton.js";
import ProviderRegistration from "./providerRegistrationButton.js";
import { Col, Container, Row, Button } from "reactstrap";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import {
  registerForAuctions,
  validateTermsAcceptanceByEventId,
} from "../../../../reducers/events/index.js";
import {
  EventTypes,
  EventTypeStrings,
} from "../../../../constants/event-types.js";
import {
  EventStatus,
  EventStatusStrings,
} from "../../../../constants/event-status.js";
import { updateUserTermsAcceptance } from "../../../../reducers/auction/index.js";
import { updateTermsAcceptance } from "../../../../reducers/account/index.js";

const EventRegistration = (props) => {
  const {
    auction,
    callback,
    hasAccount,
    push,
    registerForAuctions,
    updateUserTermsAcceptance,
    updateTermsAcceptance,
    isGuest,
    refresh_token,
    passwordRequest,
    registerForEvent = null,
    validateTermsAcceptanceByEventId,
  } = props;
  const [loginModalOpen, setLoginModal] = useState(false);
  const [termsAndConditionsDocument, setTermsAndConditionsDocument] = useState(
    ""
  );
  const [showTermsAcceptanceModal, toggleTermsAcceptanceModal] = useState(
    false
  );
  const [selectedEvent, setSelectedEvent] = useState();
  const [completeRegistrationModal, setCompleteRegistrationModal] = useState(
    false
  );

  const handViewAuctionItems = (event) => {
    let promise = new Promise((resolve, reject) => {
      resolve();
    });
    if (
      event.eventType_ID === EventTypes.Online &&
      event.eventStatus_ID === EventStatus.InProgress
    ) {
      promise.then(() => {
        push("/live-auction");
      });
    } else {
      promise.then(() => {
        push(`/search?page_number=1&events=${event.event_ID}`);
      });
    }
  };

  const handleNavigateToEvent = (id) => {
    let baseUrl = `${process.env.REACT_APP_SIMULCAST_BASE_URL}/auction/${id}`;

    if (isGuest) {
      window.open(baseUrl, "_blank");
    } else {
      window.open(baseUrl + `?token=${refresh_token}`, "_blank");
    }
  };

  const handleRegisterForEvent = async (event, callback) => {
    setSelectedEvent(event);
    const params = {
      notificationsType: {
        txtNotification: true,
        emailNotification: true,
      },
      register: !event.isRegistered,
      event_ID: event.event_ID,
      ...event,
    };
    if (!hasAccount) {
      setCompleteRegistrationModal(true);
    } else if (event.isRegistered && hasAccount) {
      await registerForAuctions(params, callback);
    } else if (!event.isRegistered && hasAccount) {
      const result = await validateTermsAcceptanceByEventId({
        event_ID: event.event_ID,
      });

      if (!result.error && !result.isCurrentTermsAcceptance) {
        setTermsAndConditionsDocument(result.termsAndConditionsDocument);
        toggleTermsAcceptanceModal(!result.isCurrentTermsAcceptance);
      } else if (!result.error && result.isCurrentTermsAcceptance) {
        await registerForAuctions(params, callback);
      }
    }
  };

  const handleGoToMyAccount = () => {
    if (!hasAccount) {
      push("/register");
    } else {
      push("/myaccount");
    }
    setCompleteRegistrationModal(!completeRegistrationModal);
  };

  const handleTermsAcceptanceClick = async () => {
    await updateUserTermsAcceptance(
      { event_ID: selectedEvent.event_ID },
      (result) => {
        toggleTermsAcceptanceModal(false);
        registerForAuctions(
          {
            notificationsType: {
              txtNotification: true,
              emailNotification: true,
            },
            register: !selectedEvent.isRegistered,
            ...selectedEvent,
          },
          callback
        );
        updateTermsAcceptance(result);
      }
    );
  };

  return (
    <React.Fragment>
      {auction.isThirdParty && (
        <ProviderRegistration
          providers={auction.providers}
          showCatalog={true}
        />
      )}
      {EventTypeStrings[auction.eventType_ID] === "Online" && (
        <div>
          <RegistrationButton
            isRegistered={auction.isRegistered}
            onRegisterClick={() =>
              registerForEvent
                ? registerForEvent(auction, callback)
                : handleRegisterForEvent(auction, callback)
            }
            isGuest={isGuest}
            setLoginModal={setLoginModal}
            loginModalOpen={loginModalOpen}
          />
          <Button
            onClick={() => handViewAuctionItems(auction)}
            className={`w-100 mb-2 btn ${auction.eventStatus_ID === EventStatus.InProgress ? "btn-danger  border-danger" : "btn-block"}`}
          >
            <span>
              {auction.eventType_ID === EventTypes.Online &&
              auction.eventStatus_ID === EventStatus.InProgress
                ? "JOIN LIVE AUCTION"
                : "VIEW INVENTORY"}
            </span>
          </Button>
        </div>
      )}
      {EventTypeStrings[auction.eventType_ID] === "Simulcast" && (
        <div>
          {auction?.providers
            ?.filter(
              (provider) =>
                provider.isCatalogURLLive || provider.isRegisterURLLive
            )
            .map((provider) => (
              <div key={provider.id} className={`w-100`}>
                <div
                  className="mb-2 btn btn-secondary w-100"
                  style={{
                    textAlign: "center",
                    background: "#555",
                    color: "#FFF",
                  }}
                >
                  {provider.name}
                </div>
                {provider.isRegisterURLLive && (
                  <Button
                    className="alert-secondary w-100 mb-2"
                    onClick={() =>
                      window.open(provider.providerRegisterURL, "_blank")
                    }
                  >
                    <i className="fa fa-calendar fa-lg boldLinkButton">
                      <span
                        style={{
                          fontFamily: "MyriadReg",
                          fontSize: "14px",
                          marginLeft: "15px",
                          textTransform: "uppercase",
                          verticalAlign: "middle",
                        }}
                      >
                        register
                      </span>
                    </i>
                  </Button>
                )}
              </div>
            ))}

          <Button
            onClick={() => handleNavigateToEvent(auction.event_ID)}
            className={`w-100 mb-2 btn ${auction.eventStatus_ID === EventStatus.InProgress ? "btn-danger  border-danger" : "btn-block"}`}
          >
            {auction.eventStatus_ID === EventStatus.InProgress ? <span>JOIN LIVE AUCTION</span> : <span>VIEW EVENT</span>}
          </Button>
          <Button
            onClick={() => handViewAuctionItems(auction)}
            className="btn btn-secondary btn-block"
          >
            <span>VIEW INVENTORY</span>
          </Button>
        </div>
      )}
      {showTermsAcceptanceModal && (
        <ConfirmationModal
          showModal={showTermsAcceptanceModal}
          turnModal={toggleTermsAcceptanceModal}
          onSubmit={handleTermsAcceptanceClick}
          handleCancelSubmit={() =>
            toggleTermsAcceptanceModal((prevState) => !prevState)
          }
          header={"Accept Event's Terms and Conditions"}
          text={`The Terms and Conditions have changed. Please read the new version and accept to continue or you will not be able to register for the event.`}
          terms={termsAndConditionsDocument}
        />
      )}
      {loginModalOpen && (
        <RegisterForm
          {...{
            passwordRequest,
          }}
          isNavEvent={true}
          toggle={() => setLoginModal(!loginModalOpen)}
          isOpen={loginModalOpen}
        />
      )}
      {completeRegistrationModal && (
        <RegisterCompleteModal
          header={"Complete Registration"}
          children={
            <h3>To register for events please complete your registration</h3>
          }
          footer={
            <Button
              style={{ width: "100%" }}
              onClick={() => handleGoToMyAccount()}
              className="btn btn-blue"
            >
              Go to my account
            </Button>
          }
          toggle={() =>
            setCompleteRegistrationModal(!completeRegistrationModal)
          }
          isOpen={completeRegistrationModal}
        />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  isGuest: state.authorization.isGuest,
  refresh_token: state.authorization.refresh_token,
  todaysEvents: state.events.todaysEvents,
  hasAccount: state.userAccount.hasAccount,
  passwordRequest: state.register.passwordRequest,
});

const mapDispatchToProps = {
  push,
  registerForAuctions,
  updateUserTermsAcceptance,
  updateTermsAcceptance,
  validateTermsAcceptanceByEventId,
};
export default connect(mapStateToProps, mapDispatchToProps)(EventRegistration);
